
import { Options, Vue } from 'vue-class-component';

import JobRates from '@/views/settings/billing/components/JobRates.vue';
import PaymentInformation from '@/views/settings/billing/components/PaymentInformation.vue';
import BillingHistorySlot from '@/views/settings/billing/components/BillingHistory/BillingHistorySlot.vue';

import requests from '@/requests';

import {
  BillingHistory,
  BillingHistoryAPI,
  BillingJobRates, ListBillingHistory,
} from '@/interface/billing.interface';

import { convertDateToString, toParserDate } from '@/utils/date';
import { parserDateToYearmonth, possibleYearMonthDates } from '@/utils';

@Options({
  components: {
    JobRates,
    PaymentInformation,
    BillingHistorySlot,
  },
})
export default class SettingsPageBilling extends Vue {
  public jobRates: BillingJobRates[] = [];

  public jobRatesLoader = false;

  public billingHistory: ListBillingHistory[] = [];

  private count = 0;

  private accessibleBillingDate: string[] = [];

  private stopBillingPromise = false;

  private getJobRatesByCurrentMonth(): void {
    this.jobRatesLoader = true;

    const date = toParserDate();

    requests.billing.getBillingJobRates(`${date.year.count}-${convertDateToString(date.month.month)}`).then((res) => {
      this.jobRatesLoader = false;
      this.jobRates = res.data;
    });
  }

  private spliceBillingHistory(
    date: string,
    data: BillingHistory | BillingHistoryAPI | null,
    key: 'jobs' | 'api',
  ): void {
    const historyIndex = this.billingHistory.findIndex((v) => v[date]);

    if (historyIndex !== -1) {
      const history = this.billingHistory[historyIndex];

      (history[date][key] as BillingHistory | BillingHistoryAPI | null) = data;
      this.billingHistory.splice(historyIndex, 1, history);
    } else {
      const obj: ListBillingHistory = {};

      obj[date] = {};
      (obj[date][key] as BillingHistory | BillingHistoryAPI | null) = data;

      this.billingHistory.push(obj);
    }
  }

  private fillingAccessibleBillingDate(): void {
    const currentDate = toParserDate();
    const joinedDate = toParserDate(this.$store.state.profile!.date_joined);

    // eslint-disable-next-line
    this.accessibleBillingDate = possibleYearMonthDates(parserDateToYearmonth(currentDate), parserDateToYearmonth(joinedDate));

    // this.accessibleBillingDate = ['2021-12'];

    this.getAllBillingHistoryByDate(this.accessibleBillingDate[this.count]);
  }

  private getBillingHistory(date: string): Promise<void> {
    return new Promise((resolve) => {
      requests.billing.getBillingHistory(date)
        .then((res) => {
          this.spliceBillingHistory(date, res.data, 'jobs');
        })
        .catch(() => {
          this.spliceBillingHistory(date, null, 'jobs');
        })
        .finally(() => {
          resolve();
        });
    });
  }

  private getBillingHistoryAPI(date: string): Promise<void> {
    return new Promise((resolve) => {
      requests.billing.getBillingHistoryAPI(date)
        .then((res) => {
          this.spliceBillingHistory(date, res.data, 'api');
        })
        .catch(() => {
          this.spliceBillingHistory(date, null, 'api');
        })
        .finally(() => {
          resolve();
        });
    });
  }

  private getAllBillingHistoryByDate(date: string): void {
    Promise.all(([this.getBillingHistory(date), this.getBillingHistoryAPI(date)])).then(() => {
      if (!this.stopBillingPromise) {
        this.count += 1;

        if (this.accessibleBillingDate[this.count]) {
          this.getAllBillingHistoryByDate(this.accessibleBillingDate[this.count]);
        }
      }
    });
  }

  created(): void {
    // TODO: uncoment before push to prod
    this.fillingAccessibleBillingDate();

    this.getJobRatesByCurrentMonth();
  }

  unmounted(): void {
    this.stopBillingPromise = true;
  }
}
