import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a9cb266"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "purchase-modal__footer" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Purchase = _resolveComponent("Purchase")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    class: "purchase-modal",
    onClose: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Purchase, {
        title: "Payment methods",
        listTitle: "Select your payment method for this job",
        onSelected: _cache[0] || (_cache[0] = ($event: any) => (_ctx.paymentMethod = $event))
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          class: "btn border mini small m-right-15",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
        }, "Cancel"),
        _createElementVNode("button", {
          class: "btn fill disabled medium mini",
          disabled: !_ctx.paymentMethod || _ctx.paymentMethod.default,
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.changeDefaultPM && _ctx.changeDefaultPM(...args)))
        }, " Save changes ", 8, _hoisted_2)
      ])
    ]),
    _: 1
  }, 8, ["onClose"]))
}