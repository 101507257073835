
import { Options, Vue } from 'vue-class-component';

import Modal from '@/common/base/Modal.vue';

import Purchase from '@/modals/components/Purchase.vue';
import requests from '@/requests';
import { PaymentMethod } from '@/interface/payment.interface';

@Options({
  components: {
    Modal,
    Purchase,
  },
  emits: {
    close: Boolean,
    update: Boolean,
  },
})
export default class ModalChangeDefaultPM extends Vue {
  public paymentMethod: PaymentMethod | null = null;

  public changeDefaultPM(): void {
    if (!this.paymentMethod?.default) {
      this.changeShowModalLoader(true);

      requests.billing.setDefaultPM(this.paymentMethod!.stripe_id)
        .then(() => {
          this.$emit('update', this.paymentMethod);
        })
        .finally(() => {
          this.changeShowModalLoader(false);
        });
    }
  }

  public closeModal(): void {
    this.$emit('close', true);
  }

  private changeShowModalLoader(status: boolean): void {
    this.$store.commit('changeShowModalLoader', status);
  }
}
