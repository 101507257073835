
import { Options, Vue } from 'vue-class-component';

import CreditCard from '@/common/PaymentInformation/CreditCard.vue';
import requests from '@/requests';

import ModalChangeDefaultPM from '@/modals/ChangeDefaultPM.vue';
import { PaymentMethod } from '@/interface/payment.interface';

@Options({
  components: {
    CreditCard,
    ModalChangeDefaultPM,
  },
})
export default class SettingsPagePaymentInfo extends Vue {
  public defaultPaymentMethod: PaymentMethod | null = null;

  public listPaymentMethods: PaymentMethod[] = [];

  public loaderPM = true;

  public isShowEditDefaultPM = false;

  private subscribe: any;

  public updateBillingMethods(pm: PaymentMethod): void {
    this.isShowEditDefaultPM = false;

    this.defaultPaymentMethod = pm;
  }

  private getAvailableBillingMethods(): void {
    requests.billing.getAvailableBillingMethods()
      .then((res) => {
        this.listPaymentMethods = res.data.payment_methods;

        this.defaultPaymentMethod = res.data.payment_methods.find((v) => v.default) || null;
      })
      .finally(() => {
        this.loaderPM = false;
      });
  }

  created(): void {
    this.getAvailableBillingMethods();

    this.subscribe = this.$store.subscribe((m) => {
      if (m.type === 'update' && m.payload === 'billing') {
        this.getAvailableBillingMethods();
      }
    });
  }

  unmounted(): void {
    this.subscribe();
  }
}
