import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "billing-history__accordion__header" }
const _hoisted_2 = { class: "billing-history__accordion__header--title" }
const _hoisted_3 = { class: "price" }
const _hoisted_4 = { class: "c-primary-grey" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Accordion = _resolveComponent("Accordion")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getHistoryPrepayment, (record, index) => {
    return (_openBlock(), _createBlock(_component_Accordion, {
      class: "m-bottom-20",
      key: index
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, [
              _createElementVNode("b", null, "$" + _toDisplayString(record.amount.toFixed(2)), 1)
            ]),
            _createElementVNode("p", _hoisted_4, [
              _createElementVNode("b", null, "Monthly prepayment " + _toDisplayString(_ctx.getCreateDate(record.created)), 1)
            ])
          ])
        ])
      ]),
      _: 2
    }, 1024))
  }), 128))
}