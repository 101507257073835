
import { Options, Vue } from 'vue-class-component';

import JobFileName from '@/common/JobFileName.vue';

import Pagination from '@/common/Pagination/Pagination.vue';

import { BillingHistory, BillingHistoryResult, BILLING_HISTORY_TYPE } from '@/interface/billing.interface';

import { BILLING_ON, JOB_TYPE_TITLE } from '@/interface/job.interface';
import { toParserDate } from '@/utils/date';

@Options({
  components: {
    JobFileName,
    Pagination,
  },
  props: {
    history: Object,
  },
})
export default class BillingHistoryAccordion extends Vue {
  declare $props: {
    history: BillingHistory;
  }

  public BILLING_ON = BILLING_ON;

  public JOB_TYPE_TITLE = JOB_TYPE_TITLE;

  public settingsPagination = {
    records: 0,
    perPage: 10,
  }

  public rawHistoryJob: BillingHistoryResult[] = [];

  private currentPage = 1;

  get renderArray(): BillingHistoryResult[] {
    const raw = [...this.rawHistoryJob];
    const start = this.settingsPagination.perPage * this.currentPage - this.settingsPagination.perPage;

    return raw.splice(start, this.settingsPagination.perPage);
  }

  public paginate(paginate: number): void {
    this.currentPage = paginate;
  }

  public uploadDate(date: string): string {
    const d = toParserDate(date);

    // eslint-disable-next-line
    return `${d.month.short} ${d.day.day}, ${d.year.count} ${d.time.hour[12]}:${d.time.minute} ${d.time.partDay.toUpperCase()}`;
  }

  created(): void {
    this.rawHistoryJob = this.$props.history.results.filter((r) => r.record_type === BILLING_HISTORY_TYPE.JOB);
    this.settingsPagination.records = this.rawHistoryJob.length;
  }
}
