/* eslint-disable no-use-before-define, camelcase */

export interface PricingApi {
  address_ncoa: number;
  address_pcoa: number;
  address_fp_found_phone_landline_phones: number;
  address_fp_found_phone_wireless_phones: number;
  phone_fp_found_phone_wireless_phones: number;
  phone_fp_found_phone_landline_phones: number;
  address_fe_valid_emails: number;
  phone_fe_valid_emails: number;
  phone_dn: number;
  phone_dn_matched: number;
  phone_fd: number
  email_found_input_valid: number;
  reverse_phone_append_no_code: number;
}

export enum PRICING_API_TITLE {
  cima = 'CIMA',
  cima_select = 'CIMA Select',
  cima_cert = 'CIMA Cert',
  cima_estimated_credit = 'CIMA with Estimated Credit',
  cima_cert_estimated_credit = 'CIMA Cert with Estimated Credit',
  cima_select_estimated_credit = 'CIMA Select with Estimated Credit',
  address_ncoa = 'Address “ncoa”',
  address_pcoa = 'Address “pcoa”',
  address_fp_found_phone_landline_phones = 'Address “fp” Found Phone Landline Phones',
  address_fp_found_phone_wireless_phones = 'Address “fp” Found Phone Wireless Phones',
  address_fe_valid_emails = 'Address “fe” Valid Emails',
  email_found_input_valid = 'Reverse Email Name and Postal “no process codes” Append',
  phone_fp_found_phone_wireless_phones = 'Reverse Phone Append: FP Wireless',
  phone_fp_found_phone_landline_phones = 'Reverse Phone Append: FP Landline',
  phone_fe_valid_emails = 'Phone "fe" Valid Emails',
  phone_dn = 'Reverse Phone Connected/Disconnected “dn” Phones',
  phone_dn_matched = 'Reverse Phone Connected/Disconnected “dn” Phones',
  phone_fd = 'Reverse Phone Demographic/Lifestyle “fd” Append',
  reverse_phone_append_no_code = 'Used No Code for Reverse Phone'
}

export enum PRICING_TITLE {
  ppr = 'Basic price',
  ppm = 'Basic price',
  minimum_price = 'Minimum price',
  standard_price = 'Standard price',
  premium_price = 'Premium Price',
  prem_append_min_price = 'Premium append min price',
  prem_append_min_price_prem_users = 'Prem Append Min Price Prem Users',
  price_18 = '18 Months',
  price_48 = '48 Months',
  add_rooftop_long_lat_price = 'Add rooftop long lat price',
  add_business_consumer_flag_price = 'Add business consumer flag price',
  estimated_credit_price = 'Estimated credit price',
  select_price = 'Select price',
  cert_price = 'Cert price',
  landline_price = 'Landline price',
  wireless_price = 'Wireless price',
}

export interface JobPricing {
  ncoa: PricingNCOA;
  consumer_pcoa: PricingConsumerPCOA;
  digital_audience: PricingDigitalAudience,
  cima: PricingCIMA;
  landline_phone_append: PricingLandlinePhoneAppend;
  wireless_phone_append: PricingWirelessPhoneAppend;
  premium_business_phone_and_contact_append: PricingPremiumBusinessPhoneAndContactAppend;
  email_append_and_validate: PricingEmailAppendAndValidate;
  email_verification: PricingEmailVerification;
  phone_disconnect: PricingPhoneDisconnect;
  canadian_ncoa: PricingCanadianNCOA;
  do_not_call_scrub: PricingDoNotCallScrub;
  reverse_phone: PricingReversePhone;
  reverse_email: PricingReverseEmail;
  api: PricingApi;
}

export type ListJobPricingWithoutAPI = PricingNCOA
| PricingConsumerPCOA
| PricingCIMA
| PricingLandlinePhoneAppend
| PricingWirelessPhoneAppend
| PricingPremiumBusinessPhoneAndContactAppend
| PricingEmailAppendAndValidate
| PricingEmailVerification
| PricingPhoneDisconnect
| PricingCanadianNCOA
| PricingDoNotCallScrub
| PricingReversePhone
| PricingReverseEmail
| PricingDigitalAudience

export type ListJobPricingWithAPI = PricingNCOA
| PricingConsumerPCOA
| PricingCIMA
| PricingLandlinePhoneAppend
| PricingWirelessPhoneAppend
| PricingPremiumBusinessPhoneAndContactAppend
| PricingEmailAppendAndValidate
| PricingEmailVerification
| PricingPhoneDisconnect
| PricingCanadianNCOA
| PricingDoNotCallScrub
| PricingReversePhone
| PricingReverseEmail
| PricingDigitalAudience
| PricingApi

export interface PricingNCOA {
  price_18: number;
  price_48: number;
  minimum_price: number;
}

export interface PricingConsumerPCOA {
  ppr: number;
  add_rooftop_long_lat_price: number;
  add_business_consumer_flag_price: number;
  minimum_price: number;
}

export interface PricingCIMA {
  ppr: number;
  minimum_price: number;
  estimated_credit_price: number;
  select_price: number;
  cert_price: number;
  cima_estimated_credit: number;
}

export interface PricingLandlinePhoneAppend {
  ppm: number;
  landline_price: number;
  wireless_price: number;
  minimum_price: number;
}

export interface PricingWirelessPhoneAppend {
  ppm: number;
  standard_price: number;
  premium_price: number;
  minimum_price: MinimumPrice;
  prem_append_min_price: number;
  prem_append_min_price_prem_users: number;
}

export interface MinimumPrice {
  standard_append_type: number;
  premium_append_type: number;
}

export enum MINIMUM_PRICE_TITLE {
  standard_append_type = 'Standard append type',
  premium_append_type = 'Premium append type',
}

export interface PricingPremiumBusinessPhoneAndContactAppend {
  ppm: number;
  minimum_price: number;
}

export interface PricingEmailAppendAndValidate {
  ppm: number;
  minimum_price: number;
}

export interface PricingEmailVerification {
  ppr: number;
  minimum_price: number;
}

export interface PricingPhoneDisconnect {
  ppr: number;
  minimum_price: number;
}

export interface PricingCanadianNCOA {
  ppr: number;
  minimum_price: number;
}

export interface PricingDoNotCallScrub {
  ppr: number;
  minimum_price: number;
}

export interface PricingReversePhone {
  ppm: number;
  minimum_price: number;
}

export interface PricingReverseEmail {
  ppm: number;
  minimum_price: number;
}

export interface PricingDigitalAudience {
  ppr: number;
  minimum_price: number;
}
