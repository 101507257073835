
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import IconsService from '@/icons/Service.vue';
import SubscriptionInfo from '@/common/SubscriptionInfo.vue';

import { SUBSCRIPTION_PLAN } from '@/subscription';
import { JOB_TYPE_TITLE } from '@/interface/job.interface';
import { BillingJobRates } from '@/interface/billing.interface';
import { ToggleModal } from '@/store/modules/modals';

@Options({
  components: {
    IconsService,
    SubscriptionInfo,
  },
  computed: {
    ...mapState(['profile']),
  },
  props: {
    jobs: Array,
    loader: Boolean,
  },
})
export default class SettingsPageBillingJobRates extends Vue {
  declare $props: {
    jobs: BillingJobRates,
    loader: boolean;
  }

  public JOB_TYPE_TITLE = JOB_TYPE_TITLE;

  public SUBSCRIPTION_PLAN = SUBSCRIPTION_PLAN;

  public showModalJobRates(): void {
    this.$store.commit('modals/toggle', { modal: 'jobRate', status: true } as ToggleModal);
  }

  public showModalChangeSubscription(): void {
    this.$store.commit('modals/toggle', { modal: 'changeSubscription', status: true } as ToggleModal);
  }
}
