
import { Options, Vue } from 'vue-class-component';

import Accordion from '@/common/Accordion.vue';

import { BillingHistory, BillingHistoryResult, BILLING_HISTORY_TYPE } from '@/interface/billing.interface';
import { toParserDate } from '@/utils/date';

@Options({
  components: {
    Accordion,
  },
  props: {
    history: Object,
  },
})
export default class BillingHistoryPrepayment extends Vue {
  declare $props: {
    history: BillingHistory;
  }

  get getHistoryPrepayment(): BillingHistoryResult[] {
    return [...this.$props.history.results].filter((v) => v.record_type === BILLING_HISTORY_TYPE.PREPAYMENT);
  }

  public getCreateDate(created: string): string {
    const date = toParserDate(created);
    const transform = (date: number): string => (date >= 10 ? String(date) : `0${date}`);
    return `${transform(date.month.month)}.${transform(date.day.day)}.${date.year.count}`;
  }
}
