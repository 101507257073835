
import { Options, Vue } from 'vue-class-component';

import {
  Chart,
  ChartDataset,
  ChartOptions,
  registerables,
} from 'chart.js';

import Accordion from '@/common/Accordion.vue';

import { BillingHistoryAPI } from '@/interface/billing.interface';
import { PRICING_API_TITLE } from '@/interface/pricing.interface';

@Options({
  components: {
    Accordion,
  },
  props: {
    history: Object,
  },
})
export default class BillingHistoryAPIAccordion extends Vue {
  declare $props: {
    history: BillingHistoryAPI;
  }

  declare $refs: {
    canvas: HTMLCanvasElement;
  }

  public PRICING_API_TITLE = PRICING_API_TITLE;

  public jobsBilledPerMatch = [
    'address_fp_found_phone_landline_phones',
    'address_fp_found_phone_wireless_phones',
    'address_fe_valid_emails',
    'phone_dn_matched',
  ];

  public chart: Chart<'bar', number[], string> | null = null;

  get APIUsage(): string {
    const year = this.$props.history.yearmonth.split('-')[0];
    const month = this.$props.history.yearmonth.split('-')[1];

    const firstDay = this.$props.history?.days[0]?.day;
    const lastDay = this.$props.history?.days[this.$props.history.days.length - 1]?.day;

    if (firstDay && lastDay) {
      return `${month}.${firstDay} - ${month}.${lastDay}.${year}`;
    }

    return '';
  }

  private initChartJS(): void {
    // TODO: chart расчитан на один день?;

    Chart.register(...registerables);

    const ctx: CanvasRenderingContext2D | null = this.$refs.canvas?.getContext('2d');

    if (ctx) {
      const labels: string[] = [];

      const datasets: ChartDataset<'bar', number[]> = {
        label: '',
        data: [],
        hoverBackgroundColor: ['#3ac66e'],
        backgroundColor: ['#3ac66e'],
        borderWidth: 1,
      };

      const options: ChartOptions<'bar'> = {
        responsive: true,
        animations: {
          x: {
            duration: 0,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            displayColors: false,
            backgroundColor: '#383b53',
            bodyFont: {
              family: 'Ubuntu',
            },
            callbacks: {
              title: () => '',
              label: (l) => `$${l.formattedValue}`,
            },
          },
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              drawOnChartArea: true,
              color: '#c4d6f0',
              lineWidth: 1,
              drawTicks: false,
            },
            ticks: {
              color: '#7B98AE',
              padding: 5,
              font: {
                family: 'Ubuntu',
                weight: 'bold',
                size: 14,
              },
              callback: (value) => `$${value}`,
            },
          },
          x: {
            grid: {
              drawTicks: false,
              drawBorder: false,
              drawOnChartArea: false,
              color: '#c4d6f0',
              lineWidth: 0,
            },
            ticks: {
              color: '#383B53',
              font: {
                family: 'Ubuntu',
                size: 12,
                lineHeight: '14px',
              },
            },
          },
        },
      };

      this.$props.history.days.forEach((d) => {
        labels.push(d.day);
        datasets.data.push(Number(d.total.toFixed(2)));
      });

      this.chart = new Chart(ctx, { type: 'bar', data: { datasets: [datasets], labels }, options });
    }
  }

  mounted(): void {
    this.initChartJS();
  }
}
