
import { Options, Vue } from 'vue-class-component';
import { watchEffect, WatchStopHandle } from 'vue';

import requests from '@/requests';

import { downloadFileByLink } from '@/utils';
import { BillingHistory, ListBillingHistory } from '@/interface/billing.interface';

import Balance from '@/common/Balance.vue';
import BillingHistoryPrepayment from '@/views/settings/billing/components/BillingHistory/BillingHistoryPrepayment.vue';
import BillingHistoryAPI from '@/views/settings/billing/components/BillingHistory/BillingHistoryAPI.vue';
import BillingHistoryJob from '@/views/settings/billing/components/BillingHistory/BillingHistoryJob.vue';

@Options({
  components: {
    Balance,
    BillingHistoryPrepayment,
    BillingHistoryAPI,
    BillingHistoryJob,
  },
  props: {
    history: Object,
  },
})
export default class BillingHistorySlot extends Vue {
  declare $props: {
    history: ListBillingHistory;
  }

  public billingDate!: string;

  private watchStopHandle!: WatchStopHandle;

  public balance = { value: 0, total: 0 };

  public downloadBill(): void {
    this.changeShowModalLoader(true);

    requests.billing.downloadBillingReport(this.billingDate)
      .then((res) => {
        downloadFileByLink(res.data.file, true);
      })
      .finally(() => {
        this.changeShowModalLoader(false);
      });
  }

  get billingTotalPrice(): number {
    const jobs = this.$props.history[this.billingDate]?.jobs?.total?.toFixed(2);
    const api = this.$props.history[this.billingDate]?.api?.total?.toFixed(2);

    return Number(jobs || api || 0);
  }

  private setBillingDate(): void {
    const date: string = Object.keys(this.$props.history)[0];

    this.billingDate = date;

    this.calcBalance();
  }

  private changeShowModalLoader(status: boolean): void {
    this.$store.commit('changeShowModalLoader', status);
  }

  private calcBalance(): void {
    const jobsUsage: BillingHistory | undefined = this.$props.history[this.billingDate]?.jobs;

    if (jobsUsage) {
      this.balance.total = jobsUsage.total_prepaid || 0;

      if (jobsUsage.total_covered < jobsUsage.total_prepaid) {
        this.balance.value = jobsUsage.total_covered || 0;
      } else {
        this.balance.value = jobsUsage.total || 0;
      }
    }
  }

  created(): void {
    this.setBillingDate();

    this.watchStopHandle = watchEffect(this.setBillingDate);
  }

  unmounted(): void {
    this.watchStopHandle();
  }
}
