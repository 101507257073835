/* eslint-disable camelcase, max-len, import/no-cycle */

import { EditedOptions } from '@/common/JobOptions/jobOptions.interface';
import { JobResult, JOB_STATUS, JOB_TYPE } from './job.interface';
import { SubscribeShort } from './payment.interface';

export enum BILLING_HISTORY_TYPE {
  PREPAYMENT = 'PREPAYMENT',
  JOB = 'JOB',
  API_USAGE = 'API_USAGE',
}

export enum BILLING_PREPAYMENT_COVERAGE {
  full = 'full',
  partial = 'partial',
  not_covered = 'not_covered',
}

export enum PAYMENT_STATUS {
  pending = 'pending',
  completed = 'completed',
  failed = 'failed',
}

export interface BillingHistoryJob {
  id: number;
  file: string;
  rows: number;
  valid_rows: number;
  job_type: JOB_TYPE;
  size: number;
  uploaded: string;
  status: JOB_STATUS;
  downloaded: boolean;
  estimated_price: number;
  final_price: number;
  possible_price: {
    standard: number;
    premium: number;
  };
  item_price: number;
  source: string;
  errors: any[];
  result: JobResult;
  options: EditedOptions | null;
}

export interface BillingHistoryResult {
  id: number;
  record_type: BILLING_HISTORY_TYPE;
  amount: number;
  job: BillingHistoryJob | null;
  prepayment_coverage: BILLING_PREPAYMENT_COVERAGE;
  amount_covered: number;
  amount_exceeded: number;
  created: string;
  pm_last4: number;
  plan: SubscribeShort;
}

export interface BillingHistory {
  count: number;
  next: string | null;
  previous: string | null;
  results: BillingHistoryResult[];
  total: number;
  total_covered: number;
  total_job: number;
  total_prepaid: number;
}

export interface BillingHistoryAPIItems {
  name: JOB_TYPE;
  count: number;
  price: number;
  total: number;
}

export interface BillingHistoryAPIDays {
  day: string
  total: number
  items: BillingHistoryAPIItems[]
}
export interface BillingHistoryAPI {
  yearmonth: string;
  total: number;
  items: BillingHistoryAPIItems[]
  days: BillingHistoryAPIDays[]
}

export type ListBillingHistory = { [date: string]: { jobs?: BillingHistory, api?: BillingHistoryAPI } };

export interface BillingJobRates {
  count: number;
  job_type: JOB_TYPE;
  price: number;
}
